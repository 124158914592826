<template>
    <div class="logo-container">
		<img id="logo" alt="Structure logo" src="../assets/structure-logo-wht.png" @click="goBack">
        <font-awesome-icon icon='xmark' />
	</div>
    <EulaText />
</template>

<script>
import router from '@/router';
import EulaText from './EulaText.vue';

export default {
    name: 'EulaPage',
    components: {
        EulaText,
    },
    setup() {
        const goBack = () => {
            router.back();
        }
        return {
            goBack,
        }
    },
};
</script>

<style scoped lang="scss">
    @import './EulaPage.scss'
</style>