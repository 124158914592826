<template>
    <div class="modal" v-if="showModal">
		<div class="modal-content">
			<span class="close" @click="closeModal">&times;</span>
			<form v-if="activeView === 'users'" @submit.prevent="submitUserForm">
				<h1>New User</h1>
				<label>Company Name <span class="alert-hidden" id="company-alert">Cannot be blank.</span></label>
				<input type="text" v-model="userForm.companyName" @input="handleBlur($event, 'company-alert')" required />

				<label>Email <span class="alert-hidden" id="email-alert">Email must be unique.</span></label>
				<input type="email" v-model="userForm.email" @input="handleBlur($event, 'email-alert')" required />

				<label>Password <span id="password-rules">12-24 characters; at least 1 number and 1 special character</span></label>
				<input type="password" v-model="userForm.password" @input="handleBlur($event, 'password-rules')" required />

				<ul class="password-constraints">
					<li :class="{ 'constraint-met': constraint.status, 'constraint-not-met': !constraint.status }" v-for="constraint in passwordConstraints" :key="constraint.id">
						{{ constraint.text }}
						<font-awesome-icon v-if="constraint.status" icon="check" class="check-icon" />
					</li>
				</ul>
				<div id="enablement-checkboxes">
				<label>Admin?</label>
				<input type="checkbox" v-model="userForm.isSuperuser" />  |

				<label>Enable User?</label>
				<input type="checkbox" v-model="userForm.isEnabled" />

				<br />
				<label>Enable Cloud Processing?</label>
				<input type="checkbox" v-model="userForm.isProcessingEnabled" /> |

				<label>Enable SDK Usage?</label>
				<input type="checkbox" v-model="userForm.isSdkEnabled" />

			</div>

				<button type="submit" :disabled="!isUserFormValid">Submit</button>
			</form>

			<form v-else-if="activeView === 'api-keys'" @submit.prevent="submitApiKeysForm">
				<h1>New API Key</h1>
				<label>Name</label>
				<input type="text" v-model="apiKeyForm.name" required />

				<button type="submit">Submit</button>
			</form>

			<form v-else-if="activeView === 'sdk-keys'" @submit.prevent="submitSdkKeysForm">
				<h1>New SDK Key</h1>
				<label>Name</label>
				<input type="text" v-model="sdkKeyForm.name" required />

				<label>Bundle ID</label>
				<input type="text" v-model="sdkKeyForm.bundle" required />

				<button type="submit">Submit</button>
			</form>
		</div>
	</div>
</template>

<script>
	import { ref, computed } from 'vue';
	import { useStore } from 'vuex';

	export default {
		name: 'DynamicFormModal',
		props: {
			showModal: Boolean,
			clickedItemLabel: String,
			emitFunction: Function,
		},
		setup(props) {
			const store = useStore();
			const activeView = computed(() => props.clickedItemLabel);

			const userForm = ref({
				companyName: '',
				email: '',
				password: '',
				isSuperuser: false,
				isEnabled: false,
				isProcessingEnabled: false,
				isSdkEnabled: false
			});

			const apiKeyForm = ref({
				name: '',
			});

			const sdkKeyForm = ref({
				name: '',
				bundle: '',
			});

			const closeModal = () => {
				store.commit('SET_SHOW_DYNAMIC_MODAL', false);
			}

			const validateUserEmail = () => {
				const userEmails = store.state.apiResponseObject.map(item => item.email);
				if (userEmails.includes(userForm.value.email)){
					document.getElementById('email-alert').classList.remove('alert-hidden');
					document.getElementById('email-alert').classList.add('alert');
				} else {
					document.getElementById('email-alert').classList.remove('alert');
					document.getElementById('email-alert').classList.add('alert-hidden');
				}
				return (!userEmails.includes(userForm.value.email));
			}

			const validateUserPassword = () => {
				const minNumberofChars = 12;
				const maxNumberofChars = 24;
				const regularExpression = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{12,24}$/;
				return (
					userForm.value.password.length >= minNumberofChars &&
					userForm.value.password.length <= maxNumberofChars &&
					regularExpression.test(userForm.value.password)
				);
			}

			const passwordConstraints = computed(() => {
				if (userForm.value.password){
					const constraints = [
				{
					id: 1,
					text: '12-24 char',
					status: userForm.value.password.length >= 12 && userForm.value.password.length <= 24,
				},
				{
					id: 2,
					text: '1+ num',
					status: /\d/.test(userForm.value.password),
				},
				{
					id: 3,
					text: '1+ special char',
					status: /[!@#$%^&*]/.test(userForm.value.password),
				},
				];
				return constraints;
				} else {
					return false;
				}


			});

			const isUserFormValid = computed(() => {
				return (
					userForm.value.companyName !== '' &&
					userForm.value.email !== '' &&
					validateUserEmail() &&
					userForm.value.password !== '' &&
					validateUserPassword()
				);
			});

			const handleBlur = (event, errMsgField) => {
				const isValid = event.target.checkValidity();
				if (!isValid) {
					event.target.classList.add('input-error');
					document.getElementById(errMsgField).classList.remove('alert-hidden');
					document.getElementById(errMsgField).classList.add('alert');
				} else {
					event.target.classList.remove('input-error');
					document.getElementById(errMsgField).classList.add('alert-hidden');
					document.getElementById(errMsgField).classList.remove('alert');
				}
			}

			const submitUserForm = () => {
			// Handle form submission for Users form
				if(isUserFormValid.value){
					props.emitFunction('submit-user-form', userForm.value);
					closeModal();
				}
			}

			const submitApiKeysForm = () => {
			// Handle form submission for API Keys form
				props.emitFunction('submit-api-keys-form', apiKeyForm.value);
				closeModal();
			}

			const submitSdkKeysForm = () => {
				props.emitFunction('submit-sdk-keys-form', sdkKeyForm.value);
				closeModal();
			}


			return {
				userForm,
				activeView,
				apiKeyForm,
				sdkKeyForm,
				closeModal,
				validateUserEmail,
				validateUserPassword,
				passwordConstraints,
				isUserFormValid,
				handleBlur,
				submitUserForm,
				submitApiKeysForm,
				submitSdkKeysForm,
			};
		},
	};
</script>


<style lang="scss" scoped>
	@import './CommonModalStyles.scss'
	@import './DynamicFormModal.scss'

</style>
