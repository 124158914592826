<template>
	<div>
	<table>
		<tbody>
		<tr>
			<th>Name</th>
			<td>{{ user.name }}</td>
		</tr>
		<tr>
			<th>Email</th>
			<td>{{ user.email }}</td>
		</tr>
		<tr>
			<th>Status</th>
			<td>
			<span v-if="permissions.length === 0">
				<p>Inactive <font-awesome-icon icon="spinner" spin style="color: #3ed333;" /></p>
				<p>Please reach out to <a href="mailto:partners@structure.io" target="_blank">our cloud team</a> to finish account activation.</p>
			</span>
			<span v-else>Active</span>
			</td>
		</tr>
		<tr v-if="formattedRoles.length > 0">
			<th>Roles</th>
			<td>
			<div v-for="(role, index) in formattedRoles" :key="index" class="access-identifier">
				{{ role }}
			</div>
			</td>
		</tr>
		<tr v-if="formattedPermissions.length > 0">
			<th>Permissions</th>
			<td>
			<div v-for="(permission, index) in formattedPermissions" :key="index" class="access-identifier">
				{{ permission }}
			</div>
			</td>
		</tr>
		</tbody>
	</table>
	</div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useAuth0 } from '@auth0/auth0-vue';

export default {
  name: 'AccountPage',
  setup() {
    const store = useStore();
    const { user } = useAuth0();

    const roles = store.state.authentication.roles;
    const permissions = store.state.authentication.permissions;

    // Create aliases for roles and permissions
    const formattedRoles = computed(() => roles?.map(role => formatRole(role)) || []);
    const formattedPermissions = computed(() => permissions?.map(permission => formatPermission(permission)) || []);

    // Helper functions to format roles and permissions
    const formatRole = (role) => {
      return role.replace(/-/g, ' ');
    };

    const formatPermission = (permission) => {
      return permission.replace(/_/g, ' ');
    };

    // Check if there are roles or permissions
    const hasRoles = computed(() => formattedRoles.value.length > 0);
    const hasPermissions = computed(() => formattedPermissions.value.length > 0);

    return {
      permissions,
      user,
      formattedRoles,
      formattedPermissions,
      hasRoles,
      hasPermissions,
    };
  },
};
</script>



<style lang="scss" scoped>
	@import './AccountPage.scss'
</style>