import { createRouter, createWebHistory } from 'vue-router';
import { useAuth0 } from "@auth0/auth0-vue";
import { jwtDecode } from "jwt-decode";

import store from '@/store';

import OuterLoginContainer from '@/components/OuterLoginContainer.vue';
import DashboardContainer from '@/components/DashboardContainer.vue';
import DashboardApp from '@/components/DashboardApp.vue';
import Auth0Callback from '@/components/Auth0Callback.vue';
import EulaPage from '@/components/EulaPage.vue';

const routes = [
    {
        path: '',
        redirect: '/login',
    },
    {
        path: '/login',
        component:
        OuterLoginContainer
    },
    {
        path: '/auth0callback',
        name: 'auth0callback',
        component: Auth0Callback,
    },
    {
        name: 'Dashboard',
        path: '/dashboard/:activeView',
        component: DashboardContainer,
        meta: { requiresAuth: true },
        children: [
            {
                name: 'DashboardView',
                path: '',
                components: {
                    default: DashboardApp,
                },
            },
        ],
    },
    {
        name: 'Eula',
        path: '/eula',
        component: EulaPage
    }
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
});


router.beforeEach(async (to, from, next) => {
    const { isAuthenticated, user } = useAuth0();

    if (isAuthenticated) {
      const { getAccessTokenSilently } = useAuth0();

      try {
        const token = await getAccessTokenSilently();
        const decoded = jwtDecode(token);
        const permissions = decoded.permissions;

        const roles = user.value['https://structure.io/roles'];
        store.commit('SET_AUTHENTICATION', { userIsAuthorized: true, roles: roles, permissions: permissions });
      } catch (error) {
        console.error('Error fetching access token:', error);
        // Handle error as needed
      }
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (isAuthenticated) {
        next();
      } else {
        router.push('/login');
      }
    } else {
      next();
    }
  });


export default router;