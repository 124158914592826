<template>
    <div class="modal">
        <div class="modal-content">
            <span class="close" @click="cancelDelete">&times;</span>
            <h2>Are you sure?</h2>
            <p>Deletions cannot be undone.</p>
            <button @click="handleConfirmDelete">Delete</button>
        </div>
    </div>
</template>

<script>
    import { computed } from 'vue';
    import { useStore } from 'vuex';

    export default {
        name: 'DeleteConfirmationModal',
        props: {
            itemData: String,
        },
        setup(props, context) {
            const store = useStore();
            const showModal = computed(() => store.state.showshowDeleteConfirmationModal);

            const handleConfirmDelete = () => {
                context.emit('confirm-delete', props.itemData);
                store.commit('SET_SHOW_DELETE_CONFIRMATION_MODAL', false);
            }

            const cancelDelete = () => {
                context.emit('cancel-delete');
            }

            return {
                showModal,
                handleConfirmDelete,
                cancelDelete,
            };
        },
    };
</script>


<style scoped lang="scss">
    @import './CommonModalStyles.scss'
    @import './DeleteConfirmationModal.scss'

</style>
