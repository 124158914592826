	<template>
		<div class="dashboard-container">
			<DashboardTopNav />
			<div class="app-wrapper">
				<DashboardAppNav
					:active-view="activeView"
					@item-clicked="handleNavItemClicked"
				/>
				<router-view :active-view="activeView" />
				<SessionSignOutModal
					v-if="showSignOutModal"
					:showModal="openSignOutModal"
					@close="closeSignOutModal"
				/>
			</div>
		</div>
	</template>

<script>
	import { computed, provide } from 'vue';
	import { useStore } from 'vuex';
	import { useRoute } from 'vue-router';

	import DashboardTopNav from './DashboardTopNav.vue';
	import DashboardAppNav from './DashboardAppNav.vue';
	import SessionSignOutModal from './modals/SessionSignOutModal.vue';

	export default {
		components: {
		DashboardTopNav,
		DashboardAppNav,
		SessionSignOutModal
		},
		setup() {
		const store = useStore();
		const route = useRoute();

		const routePath = computed(() => route.path);
		const segments = routePath.value.split('/'); // Split the path by '/'
		const activeView = segments[segments.length - 1];
		const showSignOutModal = computed(() => store.state.showSignOutModal);

		const handleNavItemClicked = (clickedItemLabel, responseData) => {
			store.commit('SET_ACTIVE_VIEW', clickedItemLabel);
			store.commit('SET_API_RESPONSE_OBJECT', responseData);
		};

		const openSignOutModal = () => {
			store.dispatch('showSignOutModal');
		};

		provide('closeSignOutModal', () => {
			store.dispatch('closeSignOutModal');
		});

		return {
			activeView,
			showSignOutModal,
			handleNavItemClicked,
			openSignOutModal
		};
		},
	};
</script>

<style scoped lang="scss">
    @import './DashboardContainer.scss'
</style>
