<template>
	<div class="appnav-container" id="nav-container">
		<h2>Collections</h2>
		<hr />
		<ul v-if="filteredNavItems.length > 0">
			<li
				v-for="item in filteredNavItems"
				:key="item.id"
				@click="handleNavItemClicked(item)"
				:class="{ active: isActiveView(item.slug) }">
				<div class="nav-item">
					<font-awesome-icon v-if="item.id === 1" icon="key" />
					<font-awesome-icon v-if="item.id === 2" icon="code" />

					{{ item.label }}
				</div>
				<!-- Check if the item is Documentation and display links -->
				<ul v-if="item.id === 3 && item.links">
					<hr />
					<li v-for="link in item.links" :key="link.text">
						<font-awesome-icon v-if="link.text === 'Get Started'" icon="flag-checkered" />
						<font-awesome-icon v-else-if="link.text ==='Endpoints'" icon="paper-plane" />
						<a :href="link.url" target="_blank">{{ link.text }}<font-awesome-icon icon="arrow-up-right-from-square" /></a>
					</li>
				</ul>
			</li>
		</ul>
		<ul v-else>
			<em>No collections found</em>
		</ul>
	</div>
</template>



<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useSCPRequest } from '../composables.js';

export default {
	name: 'DashboardAppNav',
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		const navItems = ref([
			{ id: 1, label: 'API Keys', slug: 'api-keys', requiresPermissions: ['processing_access'], api_endpoint: '/v0.1/api_token'},
			{ id: 2, label: 'SDK Keys', slug: 'sdk-keys', requiresPermissions: ['sdk_access'], api_endpoint: '/v0.1/sdk/keys'},
			{ id: 3, label: 'Cloud Docs', slug: 'documentation', requiresPermissions: ['processing_access'], links: [
				{ text: 'Get Started', url: 'https://cloud.structure.io/' },
				{ text: 'Endpoints', url: 'https://api.cloud.structure.io/api/v0.1/docs' },
			]},
		]);

		const { makeSCPRequest } = useSCPRequest();

		const isActiveView = (slug) => {
			if (route.params.activeView) {
				return route.params.activeView === slug;
			}
			return false;
		};

		const fetchData = async (apiEndpoint) => {
			try {
				store.commit('SET_CURRENT_PAGE', 1);
				const response = await makeSCPRequest(apiEndpoint, 'get', {}, { offset: '0', limit: '50' });
				return response.data;
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}

		const handleNavItemClicked = async (item) => {
    try {
        if (item.links) {
            // Handle clicks on Documentation links by opening in a new tab
            window.location.href(item.links[0].url, '_blank');
        } else {
            await fetchData(item.api_endpoint);
            router.push(`/dashboard/${item.slug}`);
        }
    } catch (error) {
        console.error('Error handling click:', error);
    }
};


		const filteredNavItems = computed(() => {
			const userPermissions = store.state.authentication.permissions || [];

			return navItems.value.filter((item) => {
				if (item.requiresPermissions) {
					return item.requiresPermissions.some((permission) => userPermissions.includes(permission));
				}
				return true;
			});
		});

		return {
			navItems,
			filteredNavItems,
			isActiveView,
			handleNavItemClicked,
		};
	},
};
</script>

<style scoped lang="scss">
    @import './DashboardAppNav.scss'
</style>
