<template>
	<button @click="handleLogin">Log In or Register</button>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";

const { loginWithRedirect } = useAuth0();

const handleLogin = () => {
  loginWithRedirect({
    appState: {
      target: "/dashboard/account",
    },
  });
};
</script>


<style scoped lang="scss">
    @import './LoginButton.scss'
</style>