<template>
	<div id="app">
		<router-view></router-view>
        <FooterContainer />
	</div>
</template>

<script>

import FooterContainer from './components/FooterContainer.vue';

export default {
    name: 'App',
    components: {
    FooterContainer,
},
    setup() {

        return {
        };
    },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import './assets/styles/variables.css';

* {
  box-sizing: border-box;
}

body {
      margin: 0;
      padding: 0;
    }
    #app {
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-sizing: border-box;
        height: 100vh;
        width: 100%;
        background-image: url('./assets/app-bg-image.png');
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
    }
</style>
