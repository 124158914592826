<template>
    <div>
        finalizing authentication . . .
    </div>
</template>


<script>

export default {
    name: 'Auth0Callback',
    setup() {
        return {
        };
    },
};
</script>
