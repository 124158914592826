<template>
	<div class="table-container">
		<table>
			<thead>
				<tr>
					<template v-for='(value, key) in apiResponseObject[0]' :key='key'>
						<th v-if="key !== 'id' && apiResponseObject.length > 0">
							{{ getColumnHeader(key) }}
							<span v-if="shouldShowSortButton(key)" class="sort-button" @click="toggleSort(key)">
								<font-awesome-icon :icon="getSortIcon(key)" />
							</span>
						</th>
					</template>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(item, index) in apiResponseObject" :key="index" :style="getRowStyle(item)">
					<template  v-for="(value, key) in item" :key="key">
						<td v-if="key !== 'id'">
						<template v-if="key !== 'id'">
							<span v-if="key=== 'value'" class="show-link" @click='showSecretString(item.value)'>
							•••• <font-awesome-icon icon="eye" title="click to show" />
							</span>
							<span v-else :style="key === 'bundle_id' ? 'font-family: monospace;' : ''">
								{{ getFormattedValue(key, value) }}
							</span>
						</template>
					</td>
					</template>

					<td>
						<span class='delete-button' @click='confirmDelete(item.id)'>
							<font-awesome-icon icon='xmark' />
						</span>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

  <script>
  import { computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';


  export default {
    name: 'KeysTable',
    setup() {
		const store = useStore();
		const route = useRoute();

		const apiResponseObject = computed(() => store.state.apiResponseObject || []);
		const activeView = computed(() => route.params.activeView);

        //function that shows the secret string value
		const showSecretString = (itemData) => {
			store.commit('SET_SECRET_VALUE', itemData);
			store.commit('SET_SHOW_SECRET_MODAL', true);
        }

		const getColumnHeader = (key) => {
			const columnHeaders = {
				created_on: 'Created On',
				last_used: 'Last Used',
				bundle_id: 'Bundle ID',
			};
			return columnHeaders[key] || key;
		};

		const formattedDateTime = (dateTimeString) => {
			if (!dateTimeString) {
				return '-';
			}
			const options = {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric',
				// hour: 'numeric',
				// minute: 'numeric',
				// hour12: true,
			};
			const date = new Date(dateTimeString);
			return date.toLocaleString('en-US', options);
		};

		const getFormattedValue = (key, value) => {
			if (key === 'created_on' || key === 'last_used') {
				return formattedDateTime(value);
			}
			return value;
		};

		const getRowStyle = (item) => {
            if (activeView.value === 'users'){
            return !item.is_enabled ? { color: 'rgba(255, 255, 255, 0.5)', backgroundColor: 'rgba(255,255,255,0.1)' } : {};
            }
        }

		//function that shows the 'Confirm delete?' modal
		const confirmDelete = (itemData) => {
            store.commit('SET_DELETE_ITEM_DATA', itemData);
            store.commit('SET_SHOW_DELETE_CONFIRMATION_MODAL', true);
        }


		const shouldShowSortButton = (key) => {
                // Decide when to show the sort button (e.g., for sortable columns)
                // You can customize this based on your requirements.
                return ['created_on', 'last_used', 'name', 'bundle_id'].includes(key);
            };

		const toggleSort = (key) => {
			// Toggle the sort direction and set the current sort column
			store.commit('TOGGLE_SORT', key);
		};

		const getSortIcon = (key) => {
			// Get the appropriate sort icon based on the current sort direction
			return store.state.currentSortColumn === key
				? store.state.sortDirection === 'asc'
					? 'sort-up'
					: 'sort-down'
				: 'sort';
		};

      return {
		apiResponseObject,
		showSecretString,
		getColumnHeader,
		getRowStyle,
		getFormattedValue,
		confirmDelete,
		shouldShowSortButton,
		toggleSort,
		getSortIcon,
      };
    },
  };
  </script>

<style scoped lang="scss">
    @import './KeysTable.scss'
</style>