<template>
	<div class="global-container">
		<div class="login-container">

		<img id="logo" alt="Structure logo" src="../assets/structure-logo-wht.png">
		<span class="beta">Beta</span>
		<h1>Cloud Platform</h1>
		<login-button />

		</div>


	</div>

</template>

<script>
	import { computed } from 'vue';
	import { useStore } from 'vuex';
	import LoginButton from './LoginButton.vue';



	export default {
	name: 'OuterFormContainer',
	components: {
		LoginButton,
	},
	setup() {
		const store = useStore();
		const isAccessTokenSet = computed(() => {
		return store.getters.isAccessTokenSet;
		});

		return {
			isAccessTokenSet,
		};
	},
	};
</script>

<style scoped lang="scss">
    @import './OuterLoginContainer.scss'
</style>