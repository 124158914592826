<template>
  <div v-if="showSignOutModal" class="session-sign-out-modal modal">
    <div class="modal-content">
      <p>Your session will expire soon. Do you want to sign out now?</p>
      <div class="button-container">
        <button @click="signOut">Sign Out</button>
        <button @click="stayLoggedIn">Stay Logged In</button>
      </div>

    </div>
  </div>
</template>

<script>
	import { computed } from 'vue';
	import { useRouter } from 'vue-router';
	import { useStore } from 'vuex';

	export default {
		setup() {
			const store = useStore();
			const router = useRouter();
			const showSignOutModal = computed(() => store.state.showSignOutModal);

			const closeSignOutModal = () => store.dispatch('closeSignOutModal');

			const signOut = () => {
				console.log(store.state.isAccessTokenSet);
				store.commit('SET_DASHBOARD_ACTIVE_VIEW', 'API Keys');
				closeSignOutModal();

				const authData = {
					accessToken: null,
					username: '',
					password: '',
					superuserStatus: false,
				};

				store.commit('SET_AUTHENTICATION', authData);
				store.dispatch('clearTimers');

				router.push({ path: '../login' });
				store.commit('SET_AUTHENTICATION', authData);
				store.commit('SET_ACCESS_TOKEN', null);
			}

			const stayLoggedIn = () => {
				closeSignOutModal();
				store.dispatch('renewToken');
				store.dispatch('startSessionTimers');
			}

			return {
				showSignOutModal,
				signOut,
				stayLoggedIn,
			};
		},
	};
</script>

<style scoped lang="scss">
    @import './CommonModalStyles.scss'
    @import './SessionSignOutModal.scss'
</style>