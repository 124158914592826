<template>
    <div class="modal">
        <div class="modal-content">
            <img src="../../assets/submitting.svg" alt="content loading. . ." />
        </div>
    </div>
</template>

<script>
    export default {
    name: 'LoadingModal',
    props: {
        showLoadingModal: Boolean,
    }
};
</script>

<style scoped lang="scss">
    @import './LoadingModal.scss'

</style>
