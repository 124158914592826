<template>
    <div class="topnav-container">
        <img id="logo" alt="Structure logo" src="../assets/structure-logo-wht.png">
        <ul>
            <div class="nav-links">
                <li class="account-link">
                    <font-awesome-icon icon="user" style="color: #ffffff" />
                    <span id="account" @click="navigateToAccount">Account</span>
                </li>
                <li>
                    <span id="logout" @click="handleLogout">Log Out</span>
                </li>
            </div>
        </ul>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';
    import { useAuth0 } from "@auth0/auth0-vue";

    const {  logout } = useAuth0();
    const router = useRouter();

    const handleLogout = () =>
    logout({
        logoutParams: {
        returnTo: process.env.VUE_APP_BASE_URL + '/login',
        }
    });

    const navigateToAccount = () => {
        router.push('/dashboard/account');
    }
</script>

<style scoped lang="scss">
    @import './DashboardTopNav.scss'
</style>