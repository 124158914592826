<template>
    <div class="modal" v-if="showModal">
        <div class="modal-content">
                <span class="close" @click="closeKeyModal">&times;</span>
                <h2>Your Key</h2>
                <p>Your key can be found below:</p>
                <p>
                    <code>{{ keyValue.value }}</code>
                    <span id="copy-key" v-if="showCopyIcon">
                        <font-awesome-icon icon="copy" @click="copyToClipboard"/>
                    </span>
                    <span v-if="showCheckIcon" id="check-key">
                        <font-awesome-icon icon="check" />
                    </span>
                </p>
                <template v-if="activeView != 'sdk-keys'">
                    <p><strong>This is the only time you will be able to see this.</strong> Please copy it and keep it in a safe place.</p>
                </template>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

export default {
    name: 'KeyModal',
    props: {
        showModal: Boolean,
        onClose: Function,
    },
    setup() {
        const store = useStore();
        const route = useRoute();

        const activeView = computed(() => route.params.activeView);
        const keyValue = computed(() => store.state.keyValue);
        const showCheckIcon = ref(false);
        const showCopyIcon = ref(true);

        const closeKeyModal = () => {
            store.commit('SET_SHOW_KEY_MODAL', false);
            store.commit('SET_KEY_VALUE', null);
        }

        const copyToClipboard = () => {
            const textArea = document.createElement('textarea');
            textArea.value = keyValue.value.value;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);

            showCheckIcon.value = true;
            showCopyIcon.value = false;

            setTimeout(() => {
                showCheckIcon.value = false;
                showCopyIcon.value = true;
            }, 3000); // 3 seconds
        }

        return {
            keyValue,
            activeView,
            showCheckIcon,
            showCopyIcon,
            closeKeyModal,
            copyToClipboard,
        };
    },
};
</script>

<style lang="scss" scoped>

	@import './CommonModalStyles.scss'
	@import './KeyModal.scss'

</style>
