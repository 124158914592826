<template>
    <div class="modal" v-if="showModal">
        <div class="modal-content">
                <span class="close" @click="closeSecretModal">&times;</span>
                <h2>Hey there!</h2>
                <p>The requested key can be found below:</p>
                <p>
                    <code>{{ secretValue }}</code>
                    <span id="copy-key" v-if="showCopyIcon">
                        <font-awesome-icon icon="copy" @click="copyToClipboard"/>
                    </span>
                    <span v-if="showCheckIcon" id="check-key">
                        <font-awesome-icon icon="check" />
                    </span>
                </p>
        </div>
    </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

export default {
    name: 'SecretModal',
    props: {
        showModal: Boolean,
        onClose: Function,
    },
    setup() {
        const store = useStore();
        const secretValue = computed(() => store.state.secretValue);
        const showCheckIcon = ref(false);
        const showCopyIcon = ref(true);

        const closeSecretModal = () => {
            store.commit('SET_SHOW_SECRET_MODAL', false);
            store.commit('SET_SECRET_VALUE', null);
        }

        const copyToClipboard = () => {
            const textArea = document.createElement('textarea');
            textArea.value = secretValue.value;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);

            showCheckIcon.value = true;
            showCopyIcon.value = false;

            setTimeout(() => {
                showCheckIcon.value = false;
                showCopyIcon.value = true;
            }, 3000); // 3 seconds
        }

        return {
            secretValue,
            showCheckIcon,
            showCopyIcon,
            closeSecretModal,
            copyToClipboard,
        };
    },
};
</script>

<style scoped lang="scss">
    @import './CommonModalStyles.scss'
    @import './SecretModal.scss'
</style>