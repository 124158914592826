<template>
    <div class="footer-container">
        <ul>
            <li>
                <router-link :to="{ name: 'Eula' }">EULA</router-link>
            </li>
            <li>
                &copy; XRPro, LLC {{ currentYear }}. All rights reserved.
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'FooterContainer',
    setup() {
        const currentYear = new Date().getFullYear();
        return {
            currentYear
        };
    },
};
</script>

<style scoped lang="scss">
    @import './FooterContainer.scss'
</style>